import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

export default function TimeApyLineChart({ apyLineData, avg }) {
  const data = [ { "rebaseTime": "1735261961", "rebaseAPY": "0.17822918904300278" }, { "rebaseTime": "1735336925", "rebaseAPY": "0.27807510943532776" }, { "rebaseTime": "1735524715", "rebaseAPY": "0.12877749479319034" }, { "rebaseTime": "1735606561", "rebaseAPY": "0.1487434200659823" }, { "rebaseTime": "1735780461", "rebaseAPY": "0.1206709326871136" }, { "rebaseTime": "1736089849", "rebaseAPY": "0.13184487233484754" }, { "rebaseTime": "1736262929", "rebaseAPY": "0.10119075295992941" }, { "rebaseTime": "1736506257", "rebaseAPY": "0.1105251419234714" }, { "rebaseTime": "1736739045", "rebaseAPY": "0.14437774512864654" } ]

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload);
      return (
        <div style={{ backgroundColor: "white", padding: "4px 12px" }}>
          <div
            className="label"
            style={{ color: "#BAC0CB", fontSize: "12px" }}
          >{`${payload[0].payload.rebaseTime}`}</div>
          <div className="intro">{`APY: ${payload[0].payload.rebaseAPY}%`}</div>
        </div>
      );
    }

    return null;
  };

  const formatDate = (input)=> {
    // 将输入的字符串转换为Date对象
    const date = new Date(input);
    // 获取月份和日，月份从0开始，所以需要+1
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // 格式化为MM/DD形式，确保月份和日始终为两位数
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
}
  const formatXAxis = (tickItem) => {
    return formatDate(parseInt(tickItem) * 1000);
  };

  const formatTimeForTooltip = (tickItem) => {
    const date = new Date(parseInt(tickItem) * 1000);
    return date.toLocaleString();
  };

  const minX = Math.min(...apyLineData.map((d) => d.rebaseAPY));
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={apyLineData}
        margin={{
            top: 0,
            right: 10,
            left: 0,
            bottom: 0,
          }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="rebaseTime"
          type="number"
          scale="time"
          domain={["auto", "auto"]}
          tickFormatter={formatXAxis}
          strokeWidth={minX < 0 ? 0 : 1}
        />
        <YAxis domain={['auto', 'auto']} type="number" unit={"%"} width={40} allowDataOverflow={true} />
        <Tooltip
            cursor={false}
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              console.log(payload)
              return (
                <div style={{backgroundColor: 'white', padding: '4px 12px'}}>
                  <div className="label" style={{color: '#BAC0CB', fontSize: '12px'}}>{`${formatTimeForTooltip(payload[0].payload.rebaseTime)}`}</div>
                  <div className="intro">{`APY: ${payload[0].payload.rebaseAPY}%`}</div>
                </div>
              );
            }
          
            return null;
          }}
        />
        {minX < 0 && <ReferenceLine y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />}
        <Line
          type="monotone"
          dataKey="rebaseAPY"
          stroke="#82ca9d"
          dot={false}
        />
        <ReferenceLine y={avg} stroke="gray" strokeDasharray="3 3" />
      </LineChart>
    </ResponsiveContainer>
  );
}
