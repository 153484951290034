import request from "./request";


export const getTokenLogin = (data) => {
  return request.post('/user/gettoken', data);
}

export const checkToken = (data) => {
  return request.post('/user/check', data);
}

export const getNonce = (params) => {
  return request.get('/user/nonce', { params });
}

export const checkReferralCode = (params) => {
  return request.get('/user/checkcode', { params });
}

export const getReferralNum = (params) => {
  return request.get('/user/getrefnum', { params });
}

export const createReferralCode = (data) => {
  return request.post('/user/createcode', data);
}

export const checkUser = (params) => {
  return request.get('/user/queryuser', { params });
}

export const getReferralCode = (params) => {
  return request.get('/user/getcode', { params });
}

export const getPonits = (params) => {
  return request.get('/user/getpoint', { params });
}



// export const getReferrals = (params) => {
//   return request.get('/referrals', { params });
// }

// export const verifyReferral = (params) => {
//   return request.post('/referrals/verify', params);
// }
