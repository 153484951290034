import { useEffect, useState } from "react";
import { Card, Spin, Popover, Table, Checkbox, Row, Col, Tabs } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ActivePieChart from "../charts/ActivePieChart";
import ActiveTokenInfoPieChart from "../charts/ActiveTokenInfoPieChart.js";
import ApyLineChart from "../charts/ApyLineChart.js";
import TimeApyLineChart from "../charts/TimeApyLineChart.js";
import TimeRangeSelector from "../charts/TimeRangeSelecor.js";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import TokenAbi from "../../contracts/TDToken.json";
import StragetyAbi from "../../contracts/StrategyAbi.json";
import StatsAbi from "../../contracts/StatsAbi.json";
import contractAddress from "../../contracts/contract-address.json";
import {
  chainRpc,
  chainHasStrategy,
  stragetyCoin,
  chainNumId,
  chainIdNum,
  coinPrice
} from "../../utils/config";
import { formatNumberbyNumeral } from "../../utils/util";
import { getRabseInfo, getRebaseApy } from "../../api/rebaseInfo.js";

export default function TotalValueDisplay() {
  const { t } = useTranslation();

  const stragetyList = [
    {
      stragetyName: "ExtraBase1",
      data: [],
    },
    {
      stragetyName: "ExtraBase2",
      data: [],
    },
    {
      stragetyName: "TarotBase",
      data: [],
    },
    {
      stragetyName: "ExtraOp1",
      data: [],
    },
    {
      stragetyName: "ExtraOp2",
      data: [],
    },
    {
      stragetyName: "TarotOp",
      data: [],
    },
  ];

  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chainAmountList, setChainAmountList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [stragetyInfoList, setStragetyInfoList] = useState(stragetyList);
  const [lastRebaseTime, setLastRebaseTime] = useState();
  const [tableData, setTableData] = useState();
  const [negativeTokenData, setNegativeTokenData] = useState();
  const [tokenInfoData, setTokenInfoData] = useState([]);
  const [usdcInfo, setUsdcinfo] = useState();
  const [apyData, setApyData] = useState([])
  const [averageApy, setAverageApy] = useState('')

  const fetchEachChainAmounts = async () => {
    try {
      const tempchartData = [];
      const tempchainList = [];
      const extraBase = {
        name: "ExtrafiBase",
        value: 0,
        chain: 'base',
      };
      const extraOp = {
        name: "ExtrafiOp",
        value: 0,
        chain: 'optimism',
      };
      setLoading(true);
      for (let index = 0; index < chainHasStrategy.length; index++) {
        const element = chainHasStrategy[index];
        const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
        const stragetyContract = new ethers.Contract(
          contractAddress.Stats.address,
          StatsAbi,
          provider
        );
        console.log(stragetyContract);
        console.log(element);
        const vault = await stragetyContract
          .getStrategyTotalValues(
            chainIdNum[element],
            contractAddress.Vault[element]
          )
          .catch((err) => console.log(err));
        const vaultAmount = ethers.utils.formatUnits(vault, 18);
        const singleChartData = {
          name: capitalizeFirstLetter(element) + " balance",
          value: Number(vaultAmount),
          chain: element,
        };
        const singleChain = {
          name: element,
          amount: Number(vaultAmount),
        };
        tempchartData.push(singleChartData);
        for (const key in contractAddress.Strategy[element]) {
          console.log(key);
          // const stragety = await vaultContract.strategyBalance(contractAddress.Strategy[element][key])
          const stragety = await stragetyContract.getStrategyTotalValues(
            chainIdNum[element],
            contractAddress.Strategy[element][key]
          );
          // const stragetyData = await stragetyContract.check()
          // const coinData = stragetyCoin[key]
          // console.log(key, coinData)
          // const pieData = {
          //     name: coinData[0],
          //     value: stragetyData[0]
          // }
          const stragetyAmount = ethers.utils.formatUnits(stragety, 18);
          console.log(key, stragetyAmount);
          const singleChainData = {
            name: key,
            value: Number(stragetyAmount),
            chain: element,
          };
          if (key.indexOf("ExtraBase") === 0) {
            extraBase.value += Number(stragetyAmount);
          } else if (key.indexOf("ExtraOp") === 0) {
            extraOp.value += Number(stragetyAmount)
          } else {
            tempchartData.push(singleChainData);
          }
          singleChain.amount = singleChain.amount + Number(stragetyAmount);
        }
        if (element === 'base') {
          tempchartData.push(extraBase);
        }else if (element === 'optimism') {
          tempchartData.push(extraOp);
        }
        tempchainList.push(singleChain);
      }
      setChartData([...tempchartData]);
      setChainAmountList([...tempchainList]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const capitalizeFirstLetter = (word) => {
    if (typeof word !== "string" || word.length === 0) {
      return "";
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const fetchTotalTD = async () => {
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const tdContract = new ethers.Contract(
      contractAddress.TDToken.base,
      TokenAbi,
      provider
    );
    const rebaseContract = new ethers.Contract(
      contractAddress.TDToken.base,
      TokenAbi,
      provider
    );
    const totalTd = await tdContract.totalSupply();
    const rebaseTime = await rebaseContract.lastRebaseTime();
    setTotalAmount(ethers.utils.formatUnits(totalTd, 18));
    if (parseInt(rebaseTime)) {
      const dateTime = new Date(parseInt(rebaseTime) * 1000);
      const formatTime = dateTime.toLocaleString();
      setLastRebaseTime(formatTime);
    } else {
      setLastRebaseTime("null");
    }
  };

  const fetchExcludeCoin = async () => {
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const statsContract = new ethers.Contract(
      contractAddress.Stats.address,
      StatsAbi,
      provider
    );
    const extraTokenAddress = "0x2dAD3a13ef0C6366220f989157009e501e7938F8";
    const extraBase1 = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.ExtraBase1,
      extraTokenAddress
    );
    const extraBase2 = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.ExtraBase2,
      extraTokenAddress
    );
    const extraBase1Amount = ethers.utils.formatUnits(
      extraBase1.tokenAmount,
      18
    );
    const extraBase2Amount = ethers.utils.formatUnits(
      extraBase2.tokenAmount,
      18
    );
    const extraAmount = formatNumberbyNumeral(
      Number(extraBase1Amount) + Number(extraBase2Amount)
    );
    const data = [
      {
        key: "1",
        coin: "Extra",
        amount: extraAmount,
      },
    ];
    console.log("extraBase1Amount:", data);
    setTableData(data);
  };

  const fetchStragetyInfo = async () => {
    const tokenData = [
      {
        name: "Extra",
        value: undefined,
        amount: undefined,
      },
      {
        name: "Op",
        value: undefined,
        amount: undefined,
      },
      {
        name: "Rdnt",
        value: undefined,
        amount: undefined,
      },
      {
        name: "Arb",
        value: undefined,
        amount: undefined,
      },
      {
        name: "Eth",
        value: undefined,
        amount: undefined,
      },
      {
        name: "OX",
        value: undefined,
        amount: undefined,
      },
      {
        name: "WELL",
        value: undefined,
        amount: undefined,
      },
    ];
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const statsContract = new ethers.Contract(
      contractAddress.Stats.address,
      StatsAbi,
      provider
    );
    const usdc = await statsContract.calculateTotalChainValues();
    setUsdcinfo({
      name: "usdc",
      value: Number(ethers.utils.formatUnits(usdc, 18)),
      amout: Number(ethers.utils.formatUnits(usdc, 18))
    });
    console.log("usdc:", usdc.toString());

    const extraTokenAddress = "0x2dAD3a13ef0C6366220f989157009e501e7938F8";
    const extraBase1 = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.ExtraBase1,
      extraTokenAddress
    );
    const extraBase2 = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.ExtraBase2,
      extraTokenAddress
    );
    const extraBase1Amount = ethers.utils.formatUnits(
      extraBase1.tokenAmount,
      18
    );
    const extraBase2Amount = ethers.utils.formatUnits(
      extraBase2.tokenAmount,
      18
    );
    console.log("extraBase1Amount:", extraBase1Amount);
    tokenData[0].value = (Number(extraBase1Amount) + Number(extraBase2Amount)) * coinPrice.Extrafi;
    tokenData[0].amount = Number(extraBase1Amount) + Number(extraBase2Amount);

    const opAddress = "0x4200000000000000000000000000000000000042";
    const extraOp1 = await statsContract.getTokenInfoByAddresses(
      "0xa",
      contractAddress.Strategy.optimism.ExtraOp1,
      opAddress
    );
    const extraOp2 = await statsContract.getTokenInfoByAddresses(
      "0xa",
      contractAddress.Strategy.optimism.ExtraOp2,
      opAddress
    );
    console.log("extraOp1Amount:", extraOp1);
    const extraOp1Amount = ethers.utils.formatUnits(extraOp1.tokenAmount, 18);
    const extraOp2Amount = ethers.utils.formatUnits(extraOp2.tokenAmount, 18);
    tokenData[1].value =
      Number(extraOp1Amount) + Number(extraOp2Amount);
    


    const rdntAddress = "0x3082CC23568eA640225c2467653dB90e9250AaA0";
    const rdntMagpieArb = await statsContract.getTokenInfoByAddresses(
      "0xa4b1",
      contractAddress.Strategy.arbitrum.MagpieArb,
      rdntAddress
    );
    tokenData[2].value = Number(
      ethers.utils.formatUnits(rdntMagpieArb.tokenAmount, 18)
    );
    console.log("rdntMagpieArb:", rdntMagpieArb);

    const arbAddress = "0x912CE59144191C1204E64559FE8253a0e49E6548";
    const arbMagpieArb = await statsContract.getTokenInfoByAddresses(
      "0xa4b1",
      contractAddress.Strategy.arbitrum.MagpieArb,
      arbAddress
    );
    tokenData[3].value = Number(
      ethers.utils.formatUnits(arbMagpieArb.tokenAmount, 18)
    );
    console.log("arbMagpieArb:", arbMagpieArb);

    const ethValue = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.MoonwellTarotBase,
      '0x0000000000000000000000000000000000000000'
    );
    console.log("ethValue:", ethValue);
    if (ethValue.negativeGrowth) {
      const data = [
        {
          key: "1",
          coin: "ETH",
          amount: -Number(
            ethers.utils.formatUnits(ethValue.tokenAmount, 18)
          ),
        },
      ];
      setNegativeTokenData(data)
    }else{
      tokenData[4].value = Number(
        ethers.utils.formatUnits(ethValue.tokenAmount, 18)
      ) * coinPrice.ETH;
      tokenData[4].amount = Number(
        ethers.utils.formatUnits(ethValue.tokenAmount, 18)
      );
    }
    

    // const oxValue = await statsContract.getTokenInfoByAddresses(
    //   "0x2105",
    //   contractAddress.Strategy.base.MoonwellOXBase,
    //   '0xba0Dda8762C24dA9487f5FA026a9B64b695A07Ea'
    // );
    // tokenData[5].value = Number(
    //   ethers.utils.formatUnits(oxValue.tokenAmount, 18)
    // ) * coinPrice.OX;
    // tokenData[5].amount = Number(
    //   ethers.utils.formatUnits(oxValue.tokenAmount, 18)
    // );

    const wellValue = await statsContract.getTokenInfoByAddresses(
      "0x2105",
      contractAddress.Strategy.base.MoonwellTarotBase,
      '0xA88594D404727625A9437C3f886C7643872296AE'
    );
    tokenData[6].value = Number(
      ethers.utils.formatUnits(wellValue.tokenAmount, 18)
    ) * coinPrice.WELL;
    tokenData[6].amount = Number(
      ethers.utils.formatUnits(wellValue.tokenAmount, 18)
    )

    console.log("tokenData:", tokenData);
    setTokenInfoData(tokenData);
  };

  const fetchTokenInfo = async () => {
    const provider = new ethers.providers.JsonRpcProvider(chainRpc.base);
    const stragetyContract = new ethers.Contract(
      contractAddress.Strategy.base.ExtraBase1,
      StragetyAbi,
      provider
    );
  };

  const formatDate = (input)=> {
    // 将输入的字符串转换为Date对象
    const date = new Date(input);

    // 获取月份和日，月份从0开始，所以需要+1
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // 格式化为MM/DD形式，确保月份和日始终为两位数
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
}

  const handleTimeSelectChange = async(value) => {
    // ['1W', '1M', '3M', '6M', '1Y', 'ALL TIME']
    console.log(value)
    let interval = 0
    switch (value) {
      case '1W':
        interval = 7
        break;
      case '1M':
        interval = 30
        break;
      case '3M':
        interval = 90
        break;
      case '6M':
        interval = 180
        break;
      case '1Y':
        interval = 365
        break;
      case 'ALL TIME':
        interval = 10000
        break;
      default:
        break;
    }
    await handleGetApy(interval)
  }

  const handleGetApy = async(interval) => {
    const res = await getRebaseApy({interval: interval})
    setAverageApy(res.data.data.average)
    const list = res.data.data.list.map(item => {
      const dateTime = new Date(parseInt(item.rebaseTime) * 1000);
      const formatTime = dateTime.toLocaleString();
      // item.rebaseTime = formatTime
      item.rebaseAPY = Number((Number(item.rebaseAPY) * 100).toFixed(2))
      item.xTime = formatDate(formatTime)
      return item
    })
    console.log(list)
    setApyData(list)
  }


  useEffect(() => {
    const getInfo = async() => {
      const res = await getRabseInfo()
      const tempChainAmountList = []
      const data = res.data.data.lastestStrategyInfo.map(item => {
        if (!Number(item.amount)) {
          return
        }
        if(item.name === 'MoonwellImpermaxBase'){
          item.name = 'MoonwellImxBase'
        }
        item.amount = Number(ethers.utils.formatUnits(item.amount, 18))
        const singleChain = {
          name: chainNumId[item.chainid],
          amount: item.amount
        };
        tempChainAmountList.push(singleChain) 
        return item
      }).filter(x => x !== undefined);
      const merged = tempChainAmountList.reduce((acc, curr) => {
        // 检查是否已经存在该 name 的项
        const existingItem = acc.find(item => item.name === curr.name);
        
        if (existingItem) {
          // 如果存在，则累加 amount
          existingItem.amount += curr.amount;
        } else {
          // 如果不存在，则添加新的项
          acc.push({ name: curr.name, amount: curr.amount });
        }
        
        return acc;
      }, []);
      setChainAmountList(merged)
      setChartData(data)
      let coinValue = 0

      const tempNegativeList = []

      const tokenMerged = res.data.data.lastestTokenInfo.reduce((acc, curr) => {
        // 检查是否已经存在该 name 的项
        const existingItem = acc.find(item => item.name === curr.name);
        curr.amount = Number(ethers.utils.formatUnits(curr.amount, 18));
        
        if (existingItem) {
          // 如果存在，则累加 amount
          existingItem.amount += curr.amount;
        } else {
          // 如果不存在，则添加新的项
          acc.push(curr);
        }
        
        return acc;
      }, []);
      console.log("tokenMerged:", tokenMerged);
      const tokenData = tokenMerged.map(item => {
        item.value = Number(ethers.utils.formatUnits(item.value, 18)) * item.amount
        if (item.name === "WELL") {
          item.value = item.amount * coinPrice.WELL
        }
        if (item.name === "Extra") {
          setTableData([item])
        }
        if (item.negativegrowth) {
          item.amount = -item.amount
          tempNegativeList.push(JSON.parse(JSON.stringify(item)))
          return undefined
        }
        if (!Number(item.value)) {
          return
        }

        coinValue += Number(item.value)
        return item
      }).filter(x => x !== undefined);

      setNegativeTokenData(tempNegativeList)

      setUsdcinfo({
        name: "usdc",
        value: Number(ethers.utils.formatUnits(res.data.data.totalValue, 18)) - coinValue,
        amout: Number(ethers.utils.formatUnits(res.data.data.totalValue, 18)) - coinValue
      });

      setTokenInfoData(tokenData)

    }

    const getApy = async() => {
      const res = await getRebaseApy({interval: 7})
      setAverageApy(res.data.data.average)
      const list = res.data.data.list.map(item => {
        const dateTime = new Date(parseInt(item.rebaseTime) * 1000);
        const formatTime = dateTime.toLocaleString();
        // item.rebaseTime = formatTime
        item.rebaseAPY = Number((Number(item.rebaseAPY) * 100).toFixed(2))
        item.xTime = formatDate(formatTime)
        return item
      })
      // list.push({
      //   rebaseAPY: -10,
      //   rebaseTime: "1740548577"
      // })
      console.log(list)
      setApyData(list)
    }

    try {
      fetchTotalTD();
      // fetchEachChainAmounts();
      // fetchExcludeCoin();
      // fetchStragetyInfo();
      getInfo()
      getApy()
    } catch (error) {
      console.log(error);
    }
  }, []);

  const ChainBlock = ({ chainList }) => {
    console.log(chainList);
    return chainList.map((entry, index) => (
      <div className="chain-block" key={index}>
        {/* <FrownOutlined/> */}
        <div className="block-text"> {capitalizeFirstLetter(entry.name)} </div>
        <div className="block-text">
          {" "}
          {"$" + formatNumberbyNumeral(Number(entry.amount))}{" "}
        </div>
      </div>
    ));
  };

  const onCheckBoxChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      const data = JSON.parse(JSON.stringify(tokenInfoData));
      data.push(usdcInfo);
      setTokenInfoData(data);
    } else {
      const data = JSON.parse(JSON.stringify(tokenInfoData));
      data.pop();
      setTokenInfoData(data);
    }
  };

  const tableColums = [
    {
      title: "Token",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const popoverContent = (
    <div>
      <Table
        columns={tableColums}
        dataSource={tableData}
        pagination={{ position: ["none"] }}
      />
    </div>
  );

  const negativeTokenPopoverContent = (
    <div>
      <Table
        columns={tableColums}
        dataSource={negativeTokenData}
        pagination={{ position: ["none"] }}
      />
    </div>
  );

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'Assets',
      children: (
        <div>
          <h5>Last Rebase Time(Data Ending in): {lastRebaseTime}</h5>
                <div className="card-chain-blocks">
                  <ChainBlock chainList={chainAmountList} />
                </div>
                <Row>
                  <Col xl={12} xs={24} sm={24} md={24} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ActivePieChart data={chartData} />
                      {tableData && tableData.length > 0 ? (
                        <Popover
                        content={popoverContent}
                        title="Excluded Token"
                        placement="top"
                      >
                        <div>
                          <ExclamationCircleOutlined /> Some small items are
                          excluded
                        </div>
                      </Popover>
                      ): ""}
                    </div>
                  </Col>
                  <Col xl={12} xs={24} sm={24} md={24} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ActiveTokenInfoPieChart data={tokenInfoData} />
                      <Checkbox onChange={onCheckBoxChange}>
                        Include USDC
                      </Checkbox>
                      {(negativeTokenData && negativeTokenData.length > 0) ? (
                        <Popover
                        content={negativeTokenPopoverContent}
                        title="Excluded Token"
                        placement="top"
                      >
                        <div>
                          <ExclamationCircleOutlined /> Some token amounts are negative
                        </div>
                      </Popover>
                      ): ''}
                    </div>
                  </Col>
                </Row>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Stats',
      children: (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <TimeRangeSelector onTimeSelectChange={handleTimeSelectChange} />
          <div className="w-100" style={{maxWidth: '650px', backgroundColor: '#f3ebe3', padding: '14px', borderRadius: '8px'}}>
            <div className="d-flex justify-content-between">
              <h5>Average TD APY</h5>
              <h5>{Number((Number(averageApy) * 100).toFixed(2))}%</h5>
            </div>
            <div className="w-100"  style={{ height: window.innerWidth > 520? '250px': '150px'}}>
              {/* <ApyLineChart apyLineData={apyData} avg={Number((Number(averageApy) * 100).toFixed(2))} /> */}
              <TimeApyLineChart apyLineData={apyData} avg={Number((Number(averageApy) * 100).toFixed(2))} />
            </div>
          </div>
        </div>
      ),
    }
  ];

  return (
    <div className="main-container">
      <Spin spinning={loading}>
        <div className="chart-container container">
          <div className="chart-title">
            <div className="title">{t("total-value-locked")}</div>
            <div className="title value">{`$ ${formatNumberbyNumeral(
              Number(totalAmount)
            )}`}</div>
          </div>
          <div className="chart-content">
            <Card>
              <div className="card-content-container">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                
              </div>
            </Card>
          </div>
        </div>
      </Spin>
    </div>
  );
}
